import { BsTwitterX } from 'react-icons/bs';
import { FacebookIcon, FacebookShareButton, LineIcon, LineShareButton, TwitterShareButton } from 'react-share';
import { APP_URL } from 'src/constants';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import styled from 'styled-components';

const WidgetSocialShare = ({ articleLink, round = false, eventDataLayer }) => {
  const shareLink = `${APP_URL}${articleLink}`;
  return (
    <>
      <WidgetWrapper className='article-share'>
        <ul className='social'>
          <li
            //! DATALAYER
            onClick={() =>
              sendDataLayerGlobal({
                ...eventDataLayer,
                data: {
                  title: 'facebook',
                  heading: 'facebook',
                  index: 1
                }
              })
            }
          >
            <FacebookShareButton url={shareLink} aria-hidden='true' tabIndex='-1'>
              <FacebookIcon size={35} round={`${`${round}`}`} />
            </FacebookShareButton>
          </li>
          <li
            //! DATALAYER
            onClick={() =>
              sendDataLayerGlobal({
                ...eventDataLayer,
                data: {
                  title: 'twitter',
                  heading: 'twitter',
                  index: 2
                }
              })
            }
          >
            <TwitterShareButton url={shareLink} aria-hidden='true' tabIndex='-1'>
              <div className='x-wrapper'>
                <BsTwitterX size={35} round={`${round}`} />
              </div>
            </TwitterShareButton>
          </li>
          <li
            //! DATALAYER
            onClick={() =>
              sendDataLayerGlobal({
                ...eventDataLayer,
                data: {
                  title: 'line',
                  heading: 'line',
                  index: 3
                }
              })
            }
          >
            <LineShareButton url={shareLink} aria-hidden='true' tabIndex='-1'>
              <LineIcon size={35} round={`${round}`} />
            </LineShareButton>
          </li>
        </ul>
      </WidgetWrapper>
    </>
  );
};

export default WidgetSocialShare;

const WidgetWrapper = styled.div`
  .x-wrapper {
    width: 35px;
    height: 35px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      padding: 8px;
      color: #fff;
    }
    @media (max-width: 690px) {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      svg {
        padding: 5px;
      }
    }
  }
`;
